import React from "react";
import WD from "../Components/4_webdev/index";
import NV from "../Components/0_navbar/index";
import FOO from "../Components/9_footer/index";

const Wd = () => {
  return (
    <div>
      <NV />
      <WD />
      <FOO />
    </div>
  );
};

export default Wd;
