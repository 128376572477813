import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Webdev = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  });

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      backgroundColor: "var(--color1)",
    },
    text: {
      height: 80,
      width: 80,
      x: mousePosition.x - 40,
      y: mousePosition.y - 40,
      backgroundColor: "var(--color1)",
      mixBlendMode: "difference",
    },
    text2: {
      height: 80,
      width: 80,
      x: mousePosition.x - 40,
      y: mousePosition.y - 40,
      backgroundColor: "var(--color4)",
      mixBlendMode: "difference",
    },
    element: {
      height: 80,
      width: 80,
      x: mousePosition.x - 40,
      y: mousePosition.y - 40,
      backgroundColor: "var(--color3)",
      mixBlendMode: "difference",
    },
  };

  const [cursorVariant, setCursorVariant] = useState("default");

  const textEnter = () => setCursorVariant("text");
  const textEnter2 = () => setCursorVariant("text2");
  const textEnterElement = () => setCursorVariant("element");
  const textLeave = () => setCursorVariant("default");
  return (
    <div>
      <div className="PContainer">
        <motion.div
          className="Cursor"
          variants={variants}
          animate={cursorVariant}
        />
        <div>this is portfolio</div>
      </div>
    </div>
  );
};

export default Webdev;
