import React from "react";
import HHERO from "../Components/1_home/1_hero/index";
import NV from "../Components/0_navbar/index";
import FOO from "../Components/9_footer/index";

const Home = () => {
  return (
    <div>
      <NV />
      <HHERO />
      <FOO />
    </div>
  );
};

export default Home;
