import React from "react";
import ABOUT from "../Components/2_about/index";
import NV from "../Components/0_navbar/index";
import FOO from "../Components/9_footer/index";

const Home = () => {
  return (
    <div>
      <NV />
      <ABOUT />
      <FOO />
    </div>
  );
};

export default Home;
