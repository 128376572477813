import React, { useState, useEffect } from "react";
import "./navbar.css";
import { AiOutlineInstagram, AiFillLinkedin } from "react-icons/ai";
import { ImSoundcloud2 } from "react-icons/im";
import { FaPinterest, FaArrowRight } from "react-icons/fa";
import { Twirl as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";
import { Link as BLink } from "react-router-dom";

import logo from "../../images/logo.png";

const Nabvar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [notOnTop, setNotOnTop] = useState(false);
  const nvProps = () => {
    if (window.scrollY >= 50) {
      setNotOnTop(true);
    } else {
      setNotOnTop(false);
    }
  };
  window.addEventListener("scroll", nvProps);

  return (
    <motion.div
      className="NBSuper"
      initial={{ height: 120 }}
      animate={notOnTop ? { height: 60 } : { height: 120 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="NBContainer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <BLink to="/">
          <div className={notOnTop ? "NBLogo NBLogoAlt" : "NBLogo"}>
            <img src={logo} alt="Logo" />
          </div>
        </BLink>

        <div className="NBSocials">
          <AiOutlineInstagram color="var(--color1)" size="42px" />
          <AiFillLinkedin color="var(--color1)" size="42px" />
          <FaPinterest color="var(--color1)" size="37px" />
          <ImSoundcloud2 color="var(--color1)" size="35px" />
        </div>
        <div className="Hamburger1">
          <Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} size={48} />
        </div>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              className="NBMenu"
              initial={{
                opacity: 0,
                right: 40,
                top: 150,
                rotate: -360,
                scale: 0.2,
              }}
              animate={{ opacity: 1, right: 40, top: 150, rotate: 0, scale: 1 }}
              exit={{
                opacity: 0,
                right: 40,
                top: 150,
                rotate: 360,
                scale: 0.2,
              }}
              transition={{ duration: 0.5 }}
            >
              <BLink to="/">
                <div className="NVLinkC">
                  <FaArrowRight color="var(--color1)" size="35px" />
                  <p>home!</p>
                </div>
              </BLink>
              <BLink to="/pablo">
                <div className="NVLinkC">
                  <FaArrowRight color="var(--color1)" size="35px" />
                  <p>yo!</p>
                </div>
              </BLink>
              <BLink to="/portfolio">
                <div className="NVLinkC">
                  <FaArrowRight color="var(--color1)" size="35px" />
                  <p>portfolio!</p>
                </div>
              </BLink>
              <BLink to="/">
                <div className="NVLinkC">
                  <FaArrowRight color="var(--color1)" size="35px" />
                  <p>rol!</p>
                </div>
              </BLink>
              <BLink to="/">
                <div className="NVLinkC">
                  <FaArrowRight color="var(--color1)" size="35px" />
                  <p>musica!</p>
                </div>
              </BLink>
              <BLink to="/">
                <div className="NVLinkC">
                  <FaArrowRight color="var(--color1)" size="35px" />
                  <p>game dev!</p>
                </div>
              </BLink>
              <BLink to="/">
                <div className="NVLinkC">
                  <FaArrowRight color="var(--color1)" size="35px" />
                  <p>AbStRaCtO!</p>
                </div>
              </BLink>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default Nabvar;
