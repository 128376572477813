import "./App.css";

import { HashRouter, Route, Routes } from "react-router-dom";
import HOME from "./pages/home";
import YO from "./pages/yo";
import PF from "./pages/portfolio";

function App() {
  return (
    <HashRouter hashType="noslash">
      <div className="App">
        <Routes>
          <Route path="/" element={<HOME />} />
          <Route path="/pablo" element={<YO />} />
          <Route path="/portfolio" element={<PF />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
