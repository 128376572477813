import React, { useState, useEffect, useRef } from "react";
import { AiOutlineInstagram, AiFillLinkedin } from "react-icons/ai";
import { ImSoundcloud2 } from "react-icons/im";
import { FaPinterest, FaArrowRight } from "react-icons/fa";
import "./hero.css";
import {
  AnimatePresence,
  easeIn,
  easeInOut,
  motion,
  reverseEasing,
} from "framer-motion";
import { Link as BLink } from "react-router-dom";

import casa1 from "../../../images/casa 1.png";
import casa2 from "../../../images/casa 2.png";
import casa3 from "../../../images/casa 3.png";
import casa4 from "../../../images/casa 4.png";
import ventana from "../../../images/ventana.png";

const Hhero = () => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const title1 = "Presentando a:";
  const title2 = "PABLO el HERMOSO";
  const title3 = "Prólogo: parte el viaje, con el punto saltarín...";
  const title4 = "> Te invito a explorar otras partes de este sitio:";

  const [temp1, setTemp1] = useState("");
  const [temp2, setTemp2] = useState(null);
  const [temp3, setTemp3] = useState(null);
  const [temp4, setTemp4] = useState(null);
  const [ivar, setIvar] = useState(0);

  const [segundos, setSegundos] = useState(0);

  function cambiarLetras(iterador, base) {
    let temp0 = base;
    return (temp0 = temp0
      .split("")
      .map((letter, index) => {
        if (index < iterador) {
          return base[index];
        }
        return letters[Math.floor(Math.random() * 52)];
      })
      .join(""));
  }

  useEffect(() => {
    // Función que se ejecuta cada segundo
    let i1 = 0;
    let i2 = 0;
    let i3 = 0;
    let i4 = 0;
    const intervalId = setInterval(() => {
      setSegundos((prevSegundos) => prevSegundos + 1);
      setTemp1(cambiarLetras(i1, title1));
      setTemp2(cambiarLetras(i2, title2));
      setTemp3(cambiarLetras(i3, title3));
      setTemp4(cambiarLetras(i4, title4));
      i1++;
      i2++;
      i3++;
      i4++;
      if (i1 > 70 + 10 * Math.floor(Math.random() * 4)) {
        i1 = 0;
      }
      if (i2 > 60 + 10 * Math.floor(Math.random() * 4)) {
        i2 = 0;
      }
      if (i3 > 80 + 10 * Math.floor(Math.random() * 4)) {
        i3 = 0;
      }
      if (i4 > 90 + 10 * Math.floor(Math.random() * 4)) {
        i4 = 0;
      }
      //setIvar(i);
    }, 50);

    // La función de limpieza detiene el temporizador al desmontar el componente
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Se ejecuta solo una vez al montar el componente

  useEffect(() => {
    // Función que reinicia el temporizador cada 10 segundos
    const reiniciarTimer = setInterval(() => {
      setSegundos(0);
    }, 5000); // Reiniciar cada 10 segundos

    // La función de limpieza detiene el reinicio al desmontar el componente
    return () => {
      clearInterval(reiniciarTimer);
    };
  }, []); // Se ejecuta solo una vez al montar el componente

  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  });

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      backgroundColor: "var(--color1)",
    },
    text: {
      height: 80,
      width: 80,
      x: mousePosition.x - 40,
      y: mousePosition.y - 40,
      backgroundColor: "var(--color1)",
      mixBlendMode: "difference",
    },
    text2: {
      height: 80,
      width: 80,
      x: mousePosition.x - 40,
      y: mousePosition.y - 40,
      backgroundColor: "var(--color4)",
      mixBlendMode: "difference",
    },
    element: {
      height: 80,
      width: 80,
      x: mousePosition.x - 40,
      y: mousePosition.y - 40,
      backgroundColor: "var(--color3)",
      mixBlendMode: "difference",
    },
  };

  const [cursorVariant, setCursorVariant] = useState("default");

  const textEnter = () => setCursorVariant("text");
  const textEnter2 = () => setCursorVariant("text2");
  const textEnterElement = () => setCursorVariant("element");
  const textLeave = () => setCursorVariant("default");

  const [reveal1, setReveal1] = useState(false);

  const [onTarget1, setOnTarget1] = useState(false);
  const [onTarget2, setOnTarget2] = useState(false);
  const [onTarget3, setOnTarget3] = useState(false);
  const [onTarget4, setOnTarget4] = useState(false);

  const [test1, setTest1] = useState(true);

  const [onHouses, setOnHouses] = useState(false);

  const handleDrag1 = (event, info) => {
    const { x, y } = info.point;
    const targetRect = targetDiv3.current.getBoundingClientRect();

    const isOver =
      x > targetRect.left + 50 &&
      x < targetRect.right - 50 &&
      y > targetRect.top + window.scrollY + 50 &&
      y < targetRect.bottom + window.scrollY - 50;

    setOnTarget1(isOver);
    if (onTarget1 && onTarget2 && onTarget3 && onTarget4) {
      setOnHouses(true);
    }
  };

  const handleDrag2 = (event, info) => {
    const { x, y } = info.point;
    const targetRect = targetDiv1.current.getBoundingClientRect();

    const isOver =
      x > targetRect.left + 50 &&
      x < targetRect.right - 50 &&
      y > targetRect.top + window.scrollY + 50 &&
      y < targetRect.bottom + window.scrollY - 50;

    setOnTarget2(isOver);
    if (onTarget1 && onTarget2 && onTarget3 && onTarget4) {
      setOnHouses(true);
    }
  };

  const handleDrag3 = (event, info) => {
    const { x, y } = info.point;
    const targetRect = targetDiv4.current.getBoundingClientRect();

    const isOver =
      x > targetRect.left + 50 &&
      x < targetRect.right - 50 &&
      y > targetRect.top + window.scrollY + 50 &&
      y < targetRect.bottom + window.scrollY - 50;

    setOnTarget3(isOver);
    if (onTarget1 && onTarget2 && onTarget3 && onTarget4) {
      setOnHouses(true);
    }
  };

  const handleDrag4 = (event, info) => {
    const { x, y } = info.point;
    const targetRect = targetDiv2.current.getBoundingClientRect();

    const isOver =
      x > targetRect.left + 50 &&
      x < targetRect.right - 50 &&
      y > targetRect.top + window.scrollY + 50 &&
      y < targetRect.bottom + window.scrollY - 50;

    setOnTarget4(isOver);
    if (onTarget1 && onTarget2 && onTarget3 && onTarget4) {
      setOnHouses(true);
    }
  };

  const parentConst = useRef(null);
  const targetDiv1 = useRef(null);
  const targetDiv2 = useRef(null);
  const targetDiv3 = useRef(null);
  const targetDiv4 = useRef(null);

  const [clickWindow, setClickWindow] = useState(false);
  const [clickDude, setClickDude] = useState(0);
  const pokeDude = () => {
    setClickDude(clickDude + 1);
  };

  const [pop1, setPop1] = useState(false);
  const [pop2, setPop2] = useState(false);
  const [pop3, setPop3] = useState(false);
  const [pop4, setPop4] = useState(false);

  const [popup1, setPopup1] = useState(false);
  const [popup2, setPopup2] = useState(false);
  const [popup3, setPopup3] = useState(false);
  const [popup4, setPopup4] = useState(false);
  const [popup5, setPopup5] = useState(false);
  const [popupG, setPopupG] = useState(false);

  const reveal2 = (pop) => {
    if (pop == 1) {
      setPopup1(true);
      setPop1(true);
    }
    if (pop == 2) {
      setPopup2(true);
      setPop2(true);
    }
    if (pop == 3) {
      setPopup3(true);
      setPop3(true);
    }
    if (pop == 4) {
      setPopup4(true);
      setPop4(true);
    }
    if (pop == 5 && pop1 && pop2 && pop3 && pop4) setPopup5(true);
    setPopupG(true);
  };

  const unreveal2 = (pop) => {
    if (pop == 1) setPopup1(false);
    if (pop == 2) setPopup2(false);
    if (pop == 3) setPopup3(false);
    if (pop == 4) setPopup4(false);
    setPopupG(false);
  };

  return (
    <div className="HHMain">
      <motion.div
        className="Cursor"
        variants={variants}
        animate={cursorVariant}
      />

      <div className="HHCont1">
        <h3 onMouseEnter={textEnter} onMouseLeave={textLeave}>
          {temp1}
        </h3>
        <h3 onMouseEnter={textEnter} onMouseLeave={textLeave}>
          {temp2}
        </h3>
        <h2 onMouseEnter={textEnterElement} onMouseLeave={textLeave}>
          ===========================================
        </h2>
        <h2 onMouseEnter={textEnterElement} onMouseLeave={textLeave}>
          {temp4}
        </h2>
        <div className="HHNavContainer">
          <div className="HHNBtn">
            <BLink to="/pablo">
              <div className="HHLinkC">
                <FaArrowRight color="var(--color3)" size="25px" />
                <p>yo!</p>
              </div>
            </BLink>
            <BLink to="/portfolio">
              <div className="HHLinkC">
                <FaArrowRight color="var(--color3)" size="25px" />
                <p>portfolio!</p>
              </div>
            </BLink>
            <a>
              <div className="HHLinkC">
                <FaArrowRight color="var(--color3)" size="25px" />
                <p>rol!</p>
              </div>
            </a>
            <a>
              <div className="HHLinkC">
                <FaArrowRight color="var(--color3)" size="25px" />
                <p>musica!</p>
              </div>
            </a>
            <a>
              <div className="HHLinkC">
                <FaArrowRight color="var(--color3)" size="25px" />
                <p>game dev!</p>
              </div>
            </a>
            <a>
              <div className="HHLinkC">
                <FaArrowRight color="var(--color3)" size="25px" />
                <p>AbStRaCtO!</p>
              </div>
            </a>
          </div>
        </div>
        <h2 onMouseEnter={textEnterElement} onMouseLeave={textLeave}>
          ===========================================
        </h2>
        <h4 onMouseEnter={textEnter2} onMouseLeave={textLeave}>
          {temp3}
        </h4>
      </div>
      <div
        className="HHElement1"
        onClick={() => setReveal1(true)}
        onMouseEnter={textEnterElement}
        onMouseLeave={textLeave}
      >
        {/*ver transicion del tag p a la carita*/}
        <motion.p exit={{ opacity: 0 }}>{reveal1 ? "x_x'" : "CLICK!"}</motion.p>
        <div className={reveal1 ? "NoBoing" : "Boing"} />
      </div>
      {reveal1 && (
        <motion.div
          className="Chapter Chapter1"
          initial={{ opacity: 0, x: -1000 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            bounce: 0.5,
            duration: 1,
          }}
          onMouseEnter={textEnterElement}
          onMouseLeave={textLeave}
        >
          <h1>Capítulo 1: Secuencia</h1>
          <p>
            La escencia del viaje nace de la idea de que existe un destino, y
            este viaje solo ha comenzado. Con un click (y un boton que ahora
            yace muerto), se abren nuevas avenidas de destino.
          </p>
          <p>
            Ahora hay cuatro viajeros <span>arrastrados</span> por el destino
            que quieren tu atención, y un <span>título</span> que te dice que
            hacer.
          </p>
          <motion.div className="Secuencia" ref={parentConst}>
            <div className="SecuenciaSub">
              <div className="SecuenciaSub">
                <AnimatePresence>
                  {!onHouses && reveal1 && (
                    <motion.div
                      key={"k1"}
                      className={
                        onTarget1 || onHouses ? "SecBtn SecBtnHappy" : "SecBtn"
                      }
                      initial={{ scale: 0 }}
                      animate={{ scale: 1, rotate: 90 }}
                      exit={{ scale: 0 }}
                      transition={{ duration: 2 }}
                      drag
                      onDrag={handleDrag1}
                      dragMomentum={false}
                      dragConstraints={parentConst}
                    >
                      <p>{onTarget1 || onHouses ? "=)" : "=("}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
                <AnimatePresence>
                  {!onHouses && reveal1 && (
                    <motion.div
                      key={"k2"}
                      className={
                        onTarget2 || onHouses ? "SecBtn SecBtnHappy" : "SecBtn"
                      }
                      initial={{ scale: 0 }}
                      animate={{ scale: 1, rotate: 90 }}
                      exit={{ scale: 0 }}
                      transition={{ duration: 2 }}
                      drag
                      onDrag={handleDrag2}
                      dragMomentum={false}
                      dragConstraints={parentConst}
                    >
                      <p>{onTarget2 || onHouses ? "=P" : "=("}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
                <AnimatePresence>
                  {!onHouses && reveal1 && (
                    <motion.div
                      key={"k3"}
                      className={
                        onTarget3 || onHouses ? "SecBtn SecBtnHappy" : "SecBtn"
                      }
                      initial={{ scale: 0 }}
                      animate={{ scale: 1, rotate: 90 }}
                      exit={{ scale: 0 }}
                      transition={{ duration: 2 }}
                      drag
                      onDrag={handleDrag3}
                      dragMomentum={false}
                      dragConstraints={parentConst}
                    >
                      <p>{onTarget3 || onHouses ? "=D" : "=("}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
                <AnimatePresence>
                  {!onHouses && reveal1 && (
                    <motion.div
                      key={"k4"}
                      className={
                        onTarget4 || onHouses ? "SecBtn SecBtnHappy" : "SecBtn"
                      }
                      initial={{ scale: 0 }}
                      animate={{ scale: 1, rotate: 90 }}
                      exit={{ scale: 0 }}
                      transition={{ duration: 2 }}
                      drag
                      onDrag={handleDrag4}
                      dragMomentum={false}
                      dragConstraints={parentConst}
                    >
                      <p>{onTarget4 || onHouses ? "=3" : "=("}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <div className="SecuenciaSub2">
              <div className="SecCont" ref={targetDiv1}>
                <p>{onHouses ? "Calle de la Muerte #1" : "Casa del 2do"}</p>
              </div>
              <div className="SecCont" ref={targetDiv2}>
                <p>{onHouses ? "Calle de la Muerte #2" : "Ultima Casa"}</p>
              </div>
              <div className="SecCont" ref={targetDiv3}>
                <p>{onHouses ? "Calle de la Muerte #3" : "Primera Casa"}</p>
              </div>
              <div className="SecCont" ref={targetDiv4}>
                <p>{onHouses ? "Calle de la Muerte #4" : "Dame un 3°!"}</p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
      {onHouses && (
        <motion.div
          className="Chapter Chapter2"
          initial={{ opacity: 0, x: 1000 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: "spring", bounce: 0.5, duration: 1 }}
          onMouseEnter={textEnter2}
          onMouseLeave={textLeave}
        >
          <h1>Capítulo 2: Consecuencia</h1>
          <p>
            Pero con la llegada al destino llega el estancamiento. Los
            ex-viajeros se han acostumbrado a la comodidad de sus hogares y se
            han vuelto gordos y complacientes.
          </p>
          <p>
            Ayudalos a romper la inercia de la rutina dándoles un poco de
            incomodidad, para volver las cosas en nuevas una vez más...
          </p>
          <div className="HHHouses">
            <div className="HHHouse">
              <div className="HHup">
                <p>{clickWindow ? "Casa Vacante" : "Casa Ocupada"}</p>
              </div>
              <div className="HHmid">
                <img
                  src={casa1}
                  alt="casa 1"
                  className={clickWindow ? "HHmidAlt" : ""}
                />
                <motion.div
                  className="FatBtn"
                  initial={{ scale: 0 }}
                  animate={
                    clickWindow
                      ? { scale: 0.5, rotate: -15, backgroundColor: "red" }
                      : { scale: 1, rotate: 90, y: -160 }
                  }
                  transition={{ duration: 1 }}
                >
                  <p>{clickWindow ? "x_X" : "=P"}</p>
                </motion.div>
              </div>
              <div className="HHdown">
                <p>"no me gusta la oscuridad"</p>
              </div>
            </div>
            <div className="HHHouse">
              <div className="HHup">
                <p>{clickWindow ? "Casa Vacante" : "Casa Ocupada"}</p>
              </div>
              <div className="HHmid">
                <img
                  src={casa2}
                  alt="casa 2"
                  className={clickWindow ? "" : "HHmidAlt"}
                />

                <motion.div
                  className="FatBtn"
                  initial={{ scale: 0 }}
                  animate={
                    clickWindow
                      ? { scale: 0.5, rotate: 15, backgroundColor: "red" }
                      : { scale: 1, rotate: 90, y: -160 }
                  }
                  transition={{ duration: 1, delay: 0.3 }}
                >
                  <p>{clickWindow ? "x_X" : "=3"}</p>
                </motion.div>
              </div>
              <div className="HHdown">
                <p>"no me gusta tener mucha luz"</p>
              </div>
            </div>
            <div className="HHHouse">
              <div className="HHup">
                <p>
                  {clickWindow && clickDude >= 13
                    ? "Casa Vacante"
                    : "Casa Ocupada"}
                </p>
              </div>
              <div className="HHmid">
                <img src={casa3} alt="casa 3" />
                <motion.div
                  className="FatBtn"
                  initial={{ scale: 0 }}
                  animate={
                    clickWindow && clickDude >= 13
                      ? { scale: 0.5, rotate: -35, backgroundColor: "red" }
                      : { scale: 1, rotate: 90, y: -160 }
                  }
                  transition={{ duration: 1, delay: 0.3 }}
                >
                  <p>{clickWindow && clickDude >= 13 ? "x_X" : "=)"}</p>
                </motion.div>
              </div>
              <div className="HHdown">
                <p>"no me gusta que mueran todos mis vecinos"</p>
              </div>
            </div>
            <div className="HHHouse">
              <div className="HHup">
                <p>{clickDude >= 13 ? "Casa Vacante" : "Casa Ocupada"}</p>
              </div>
              <div className="HHmid">
                <img src={casa4} alt="casa 4" />
                <motion.div
                  onClick={() => pokeDude()}
                  className="FatBtn"
                  initial={{ scale: 0 }}
                  animate={
                    clickDude == 0
                      ? { scale: 1, rotate: 90, y: -160 }
                      : clickDude >= 13
                      ? { scale: 0.5, rotate: 25, backgroundColor: "red" }
                      : {
                          scale: 1,
                          rotate: 90,
                          backgroundColor: "yellow",
                          y: -160,
                        }
                  }
                  transition={{ duration: 1 }}
                >
                  <p>
                    {clickDude == 0 ? "=D" : clickDude >= 13 ? "X_x" : "=|"}
                  </p>
                </motion.div>
              </div>

              <div className="HHdown">
                <p>"no me gusta que me toquen mas de 13 veces"</p>
              </div>
            </div>
            <div className="HHWindow">
              <motion.div
                className="HHmid"
                onClick={() => setClickWindow(true)}
                //initial={{ top: 105, left: 290 }}
                animate={
                  clickWindow ? { y: -39, x: -715 } : { y: -40, x: -895 }
                }
              >
                <img src={ventana} alt="ventana sospechosa" />
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}
      {clickWindow && clickDude >= 13 && (
        <motion.div
          className="Chapter Chapter3"
          initial={{ opacity: 0, x: 1000 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: "spring", bounce: 0.5, duration: 1 }}
          onMouseEnter={textEnterElement}
          onMouseLeave={textLeave}
        >
          <h1>Capítulo 3: Silencio</h1>
          <p>
            Los mataste a todos! tan solo era una sugerencia que los incomodaras
            algo mientras te improvisaba algo que hacer... despues de todo esto
            es sólo un sitio web.
          </p>
          <p>
            Bueno, viajeros muertos, viaje acabado, ya has asesinado 5
            componentees web concientes... no queda mas que silencio.
          </p>
          <motion.div
            className="HHSpark"
            initial={{ opacity: 0, y: 50, x: 800 }}
            animate={{ opacity: 0.5, y: 50, x: 800 }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: "reverse",
              ease: easeInOut,
            }}
            onMouseEnter={() => reveal2(1)}
            onMouseLeave={() => unreveal2(1)}
          >
            <p>Soledad</p>
          </motion.div>
          <motion.div
            className="HHSpark"
            initial={{ opacity: 0, y: 50, x: 1000 }}
            animate={{ opacity: 0.5, y: 50, x: 1000 }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: "reverse",
              delay: 3,
              ease: easeInOut,
            }}
            onMouseEnter={() => reveal2(2)}
            onMouseLeave={() => unreveal2(2)}
          >
            <p>Duda</p>
          </motion.div>
          <motion.div
            className="HHSpark"
            initial={{ opacity: 0, y: 100, x: 350 }}
            animate={{ opacity: 0.5, y: 100, x: 350 }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: "reverse",
              delay: 2.5,
              ease: easeInOut,
            }}
            onMouseEnter={() => reveal2(3)}
            onMouseLeave={() => unreveal2(3)}
          >
            <p>Pérdida</p>
          </motion.div>
          <motion.div
            className="HHSpark"
            initial={{ opacity: 0, y: -100, x: 120 }}
            animate={{ opacity: 0.5, y: -100, x: 120 }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: "reverse",
              delay: 1,
              ease: easeInOut,
            }}
            onMouseEnter={() => reveal2(4)}
            onMouseLeave={() => unreveal2(4)}
          >
            <p>Monotonía</p>
          </motion.div>
          <motion.div
            className={
              pop1 && pop2 && pop3 && pop4 ? "HHSpark1 HHSpark2" : "HHSpark1"
            }
            initial={{ opacity: 0, y: -50, x: 560 }}
            animate={{ opacity: 0.5, y: -50, x: 560 }}
            transition={{
              duration: 0.5,
              repeat: Infinity,
              repeatType: "reverse",
              ease: easeInOut,
            }}
            onMouseEnter={() => reveal2(5)}
          >
            <p>ESPERANZA</p>
          </motion.div>
          <AnimatePresence>
            {popupG && !popup5 && (
              <motion.div
                className="HHPop"
                initial={{ opacity: 0, y: 60, x: 70 }}
                exit={{ opacity: 0, y: 60, x: 70 }}
                animate={{ opacity: 1, y: 60, x: 70 }}
                transition={{
                  duration: 1,
                  ease: easeInOut,
                }}
              >
                {popup1 && !popup5 && (
                  <p>
                    El vacío te envuelve en fría caricia. El frío cala tus
                    huesos, amplificando la sensación de abandono. Te abandonas
                    finalmente al vacío, perdiéndote totalmente en ls Soledad.
                  </p>
                )}
                {popup2 && !popup5 && (
                  <p>
                    ¿por qué lo hice?.. <br />
                    ¿? ¿? ¿? ¿? ¿? ¿? ¿? ¿?
                    <br />
                    ...no lo se.
                  </p>
                )}
                {popup3 && !popup5 && (
                  <p>
                    Estamos acá para conmemorar la vida de los circulitos que
                    solían habitar esta página. Fueron buenos circulitos.
                    Complacientes y ciertamente estúpidos, pero circulitos al
                    fin y al cabo. QEPD Circulitos...
                  </p>
                )}
                {popup4 && !popup5 && (
                  <p>
                    Sólo trabajo y nada de juego hacen de ti un niño aburrido{" "}
                    <br />
                    Sólo trabajo y nada de juego hacen de ti un niño aburrido{" "}
                    <br />
                    Sólo trabajo y nada de juego hacen de ti un niño aburrido{" "}
                    <br />
                  </p>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {popup5 && pop1 && pop2 && pop3 && pop4 && (
              <motion.div
                className="HHPop HHPopE"
                initial={{ opacity: 0, y: -360, x: 335 }}
                exit={{ opacity: 0, y: -360, x: 335 }}
                animate={{ opacity: 1, y: -360, x: 335 }}
                transition={{
                  duration: 1,
                  ease: easeInOut,
                }}
              >
                <h3>Esperanza.</h3>
                <p>
                  Eventualmente uno da vuelta la página. Al no cegarse con
                  sentimientos negativos, uno mira hacia adelante sin mayores
                  problemas... o en este caso para... abajo! Mira! una semilla!
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
      {popup5 && pop1 && pop2 && pop3 && pop4 && (
        <motion.div
          className="Chapter Chapter2"
          initial={{ opacity: 0, x: 1000 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: "spring", bounce: 0.5, duration: 1 }}
          onMouseEnter={textEnter2}
          onMouseLeave={textLeave}
        >
          <h1>Continuará...</h1>
        </motion.div>
      )}
    </div>
  );
};

export default Hhero;
